/*
 * This file contains common JavaScript used by KauppaSuomi-site.
 */

$(function() {
    "use strict";

    // Set up owl carousel for banner sliders.
    $(".owl-carousel.banners").owlCarousel({
        items: 4,
        nav: true,
        navText: [
            "<i class=\"fa fa-chevron-left\"></i>",
            "<i class=\"fa fa-chevron-right\"></i>"
        ],
        responsive: {
            // Breakpoint from 0 up.
            0: {
                items: 2
            },

            // Breakpoint from 768 up.
            768: {
                items: 3
            },

            // Breakpoint from 992 up.
            992: {
                items: 4
            }
        },
        responsiveClass: true
    });

    // Enable lightbox for images. Use arrows to navigate through images if
    // more than one image is available.
    $(".lightbox-images").each(function() {
        const selectorMultiple = "a[data-imagelightbox='image-multiple']";
        const instanceMultiple = $(selectorMultiple, this).imageLightbox({
            onStart: function() {
                overlayOn();
                closeButtonOn(instanceMultiple);
                arrowsOn(instanceMultiple, selectorMultiple);
            },
            onEnd: function() {
                overlayOff();
                closeButtonOff();
                arrowsOff();
            }
        });
    });

    // Set up certain <select>'s to use Bootstrap select picker.
    $("select.selectpicker").selectpicker();

    const stickyNav = function() {
        const $bannerHeight = $(".top-banner").outerHeight();
        const $navHeight = $(".main-navigation").outerHeight();
        const countedPoint = $bannerHeight + $navHeight + 100;

        // Add visible class 100px after scrolling past the ".main-navigation"
        $(".navbar-fixed-top").toggleClass(
            "visible",
            $(window).scrollTop() > countedPoint
        );
    };

    stickyNav();

    $(window).scroll(stickyNav);
});
