/*
 * This file contains common JavaScript used by both sites.
 */

$(function() {
    "use strict";

    // Date and time pickers.
    const datePickerInputs = $("input.karkkainen-datepicker");
    const dateTimePickerInputs = $("input.karkkainen-datetimepicker");

    if (datePickerInputs.length) {
        datePickerInputs.datetimepicker({
            autoclose: true,
            format: "dd.mm.yyyy",
            fontAwesome: true,
            language: "fi",
            minView: 2
        });
    }
    if (dateTimePickerInputs.length) {
        dateTimePickerInputs.datetimepicker({
            autoclose: true,
            format: "dd.mm.yyyy hh:ii",
            fontAwesome: true,
            language: "fi",
        });
    }
});
